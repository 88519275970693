<template>
  <div>
    <products-search
        :products="products"
        :options="options"
        :disabled="disabled"
        :default-open="openOffersSearch"
        @on-search="handleSearch"
        @on-close-search="closeOffersSearch"
        @submit="handleSubmit"
        @on-item-change="handleItemChange"
    >

      <q-btn
          :color="'green-4'"
          text-color="white"
          :label="$t('Confirm Inventory')  + `${selectedItems.length > 0 ? ` (${selectedItems.length})` : ''}`"
          class="q-mr-sm"
          size="sm"
          no-caps
          unelevated
          :disable="isConfirmedDisabled"
          @click="handleConfirmInventory"
      />

      <q-btn
          :color="'amber-6'"
          text-color="white"
          :label="$t('Add to Order') + `${selectedItems.length > 0 ? ` (${selectedItems.length})` : ''}`"
          class="q-mr-sm"
          size="sm"
          no-caps
          unelevated
          :disable="selectedItems.length <= 0"
          @click="handleAddToOrder"
      />

      <q-btn
          :color="'amber-6'"
          text-color="white"
          :label="$t('Add to Purchase order') + `${selectedItems.length > 0 ? ` (${selectedItems.length})` : ''}`"
          class="q-mr-sm"
          size="sm"
          no-caps
          unelevated
          :disable="selectedItems.length <= 0"
          @click="handleAddToWarehouse"
      />

      <q-btn
          color="grey-6"
          :label="$t('Request location')"
          class="q-mr-sm"
          icon="add"
          no-caps
          @click="handleDynamicLocation"
      />

      <q-btn
          color="green-6"
          text-color="white"
          :label="$t('Add item')"
          icon="add"
          no-caps
          @click="handleNewItem"
      />
    </products-search>

    <q-checkbox
        :model-value="type !== 'all'"
        :label="$t('Show products with system barcodes (S/I/PO)')"
        color="teal"
        @update:model-value="filterProductsByType"
    />

    <q-table
        style="height: 100%"
        class="sticky-header-table"
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="currentAcceptanceItems"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="isLoading"
        :filter="filter"
        selection="multiple"
        v-model:selected="selectedItems"
        :rows-per-page-options="[0, 25, 50, 100, 150, 200, 250 , 0]"
        virtual-scroll
        binary-state-sort
        flat
        :wrap-cells="true"
        @request="onRequest"
    >
      <template v-slot:loading>
        <q-inner-loading showing color="primary"/>
      </template>

      <template v-slot:header-selection="props">
        <q-checkbox
            size="xl"
            :model-value="selectedItems.length > 0"
            color="amber-6"
            @click="handleSelectAll(props)"
        />
      </template>

      <template v-slot:body="props">
        <acceptance-items-row
            v-if="props.row.state !== 'deleted'"
            :data="props"
            :disabled="disabled"
            :is-palletized="acceptance.type === 'palletized'"
            :is-batch="options.batch"
            :is-amazon="isAmazon"
            :items="selectedItems"
            :is-new="acceptance.state === 'new'"
            :is-checking="acceptance.state === 'checking'"
            :is-confirmed="acceptance.state === 'confirmed'"
            :disabled-price="isClient"
            :disabled-dimensions="isClient"
            :warehouse="acceptance?._embedded?.warehouse"
            @open="handleShowInfo"
            @change="handleChange"
            @offer-change="handleOfferUpdate"
            @search-offer="handleOfferSearch"
            @item-allocate="refresh"
            @check="handleCheck"
        />
      </template>
    </q-table>

    <q-dialog v-model="areYouSureConfirm">
      <q-card style="max-width: 30vw">
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
            {{ $t('Are You Sure?') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>
        <q-card-section class="row items-center q-pb-none">
          <div>
            {{ $t('Confirm the quantity of the items in the PO as it is in the field “Received“? ') }}
          </div>
        </q-card-section>
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <q-card-section class="text-center">

            <q-btn
                textarea="center"
                color="light-blue-9"
                class="q-mr-sm"
                text-color="white"
                :label="$t('Yes')"
                @click="confirmPurchaseOrder"
            />

            <q-btn
                textarea="center"
                :label="$t('Discard')"
                @click="closeConfirm"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>


    <offer-modal ref="offerModal" @submit="handleOfferSubmit"/>

    <acceptance-items-movement-modal ref="acceptanceModal" @save="saveItems"/>

    <tasks-patch-modal ref="tasksModal"/>

    <order-acceptance-modal ref="orderModal" :warehouse="this.acceptance?._embedded?.warehouse"/>

    <acceptance-services-modal ref="acceptanceServices"/>

    <dynamic-location-modal ref="dynamicLocationModal"></dynamic-location-modal>

    <confirm-modal ref="confirmModal"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'
import _ from 'lodash'

// Components
import ProductsSearch from './ProductsSearch.vue'
import OfferModal from '../modals/OfferModal.vue'
import AcceptanceItemsRow from '../tables/AcceptanceItemsRow.vue'
import TasksPatchModal from '../modals/TasksPatchModal.vue'
import ConfirmModal from '../confirm-modal/ConfirmModal.vue'
import AcceptanceItemsMovementModal
  from '@/apps/app/components/acceptance-items-movement-modal/AcceptanceItemsMovementModal.vue'
import AcceptanceServicesModal from '@/apps/app/components/modals/AcceptanceServicesModal.vue'
import DynamicLocationModal from '@/apps/app/components/modals/DynamicLocationModal.vue'
import OrderAcceptanceModal from '@/apps/app/components/modals/OrderAcceptanceModal.vue'

export default {
  name: 'AcceptanceItemsBulk',
  emits: ['items-change', 'change', 'save'],
  components: {
    OrderAcceptanceModal,
    AcceptanceServicesModal,
    DynamicLocationModal,
    AcceptanceItemsMovementModal,
    ProductsSearch,
    OfferModal,
    AcceptanceItemsRow,
    TasksPatchModal,
    ConfirmModal
  },
  props: {
    acceptance: {
      type: Object,
      default () {
        return null
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      type: 'all',
      items: [],
      selectedItems: [],
      search: '',
      pagination: {
        'order-by': [
          {
            type: 'field',
            field: 'id',
            direction: 'asc'
          }
        ],
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 50
      },
      updatedItems: {},
      isLoading: false,
      filter: '',
      offerChanges: {},
      openOffersSearch: false,
      isAmazon: false,
      warehouseOwner: null,
      areYouSureConfirm: false
    }
  },
  computed: {
    ...mapGetters([
      'isClient',
      'virtualShop',
      'isClient',
      'isAdministrator',
      'isSupervisior',
      'isEmployee'
    ]),
    columns () {
      return [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Weight'),
          name: 'weight',
          align: 'left'
        },
        {
          label: this.$t('Expected'),
          name: 'expected',
          align: 'left'
        },
        {
          label: this.$t('Received'),
          name: 'receiving',
          align: 'left'
        },
        {
          label: this.$t('Allocated'),
          name: 'placed',
          align: 'left'
        },
        {
          label: this.$t('Barcode'),
          name: 'sku',
          align: 'left'
        },
        this.isAmazon ?
            {
              label: this.$t('Amazon'),
              name: 'amazon',
              align: 'left'
            } : {},
        {
          label: this.$t(''),
          name: 'actions',
          align: 'left'
        }
      ]
    },

    shop () {
      if (this.items.length <= 0) {
        return null
      }

      return this.items[0]._embedded && this.items[0]._embedded.shop
    },
    options () {
      return {
        acceptance: true,
        hasBarcode: true,
        batch: true,
        isUnique: this.acceptance?.type === 'palletized'
            ? ['grouped', 'pallet']
            : [],
        disabledWarehouse: this.acceptance && this.acceptance._embedded && this.acceptance._embedded.warehouse && this.items.length > 0,
        allShops: true,
        hasComments: true,
        noWarehouseFilter: true,
        onlyOneShop: false,
        defaultValues: {
          shop: this.shop,
          warehouse: this.acceptance && this.acceptance._embedded && this.acceptance._embedded.warehouse
        },
        allowNoOffer: true,
        openOffersSearch: this.openOffersSearch,
        item: null
      }
    },
    products () {
      // Transform items to objects from which I can revert them after submit
      return this.items.map(x => {
        return {
          ...x,
          ...x._embedded.productOffer,
          state: x.state,
          itemsStatus: x.itemsStatus,
          barcode: x.sku,
          _embedded: {
            ...x._embedded,
            item: { ...x }
          }
        }
      })
    },
    currentAcceptanceItems () {
      if ((this.acceptance && this.acceptance.id) || (!this.search && this.type === 'all')) {
        return this.items
      }

      return this.items.filter(x => {
        if (this.type === 'withoutBarcode' && x.sku && !(x.sku || '').startsWith('S/I/PO')) {
          return false
        }

        return JSON.stringify({ ...x, _embedded: undefined }).toLowerCase().includes(this.search.toLowerCase())
      })
    },
    isConfirmedDisabled () {
      if (this.acceptance && (!this.isClient || this.isEmployee) && (this.acceptance.state === 'new' || this.acceptance.state === 'error' || this.acceptance.state === 'checking')) {
        return false
      }
      return true
    }
  },
  watch: {
    'acceptance._embedded.warehouse': {
      handler (value, oldValue) {
        if (value !== oldValue) {
          this.integrations()
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.acceptance && this.acceptance.id) {
      this.onRequest({ pagination: { per_page: 50, page: 1 } })
      this.integrations()
    }
  },
  unmounted () {
    this.setVirtualShop('')
  },
  methods: {
    ...mapActions([
      'loadVirtualShop',
      'loadShop'
    ]),
    ...mapMutations([
      'setAcceptance',
      'upsertAcceptance',
      'setAcceptanceItems',
      'addErrorNotification',
      'setVirtualShop'
    ]),
    confirmPurchaseOrder () {
      return this.$service.acceptance.save({ state: 'confirmed' }, this.acceptance.id)
          .then(acceptance => {
            this.setAcceptance(acceptance)
            this.upsertAcceptance(acceptance)
            this.areYouSureConfirm = false
            this.$refs.acceptanceItems.refresh()
          })
    },
    handleConfirmInventory () {
      if (this.selectedItems && this.selectedItems.length > 0) {
        this.selectedItems.map(e => {
          e.state = 'confirmed'
          e.isUpdated = true
          return e
        })
        this.$emit('items-change')
        return
      }
      this.areYouSureConfirm = true
    },
    closeConfirm () {
      this.areYouSureConfirm = false
    },
    saveItems (items = []) {
      console.log(items)
      return this.$service.acceptanceItem.saveAll(items, this.acceptance.state)
          .then(() => {
            return this.saveOffers()
          })
          .catch(err => {
            const data = {
              noCancel: true,
              okColor: 'negative',
              description: err.message
            }

            return this.$refs.confirmModal.show(data)
                .then(() => {
                  return true
                })
          })
    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    handleCheck (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.selectedItems = [item, ...this.selectedItems]
      }
    },
    handleAddToOrder () {
      let merged = []
      this.selectedItems.forEach(e => {
        if (!e?._embedded?.productOffer) {
          return
        }
        if (!merged.some(el => el?._embedded?.productOffer.id === e?._embedded?.productOffer.id)) {
          if (this.acceptance.state === 'new') {
            e.count = e.quantityExpected
          } else if (this.acceptance.state === 'confirmed' || this.acceptance.state === 'checking') {
            e.count = e.quantityReal
          } else {
            e.count = e.quantityPlace
          }
          merged.push(e)
        } else {
          merged.map(el => {
            if (el?._embedded?.productOffer.id === e?._embedded?.productOffer.id) {
              if (this.acceptance.state === 'new') {
                el.count += e.quantityExpected
              } else if (this.acceptance.state === 'confirmed' || this.acceptance.state === 'checking') {
                el.count += e.quantityReal
              } else {
                el.count += e.quantityPlace
              }
            }
          })
        }
      })
      this.$refs.orderModal.show(merged.map(item => ({ ...item, count: item.count })))
    },
    handleAddToWarehouse () {
      const updatedItems = this.selectedItems.map(item => {
        return {
          ...item,
          sku: Array.isArray(item.barcodes) && item.barcodes.length >= 1
              ? item.barcodes[0]
              : '',
          state: 'new',
          dimensions: item.dimensions && typeof item.dimensions === 'object'
              ? item.dimensions
              : { x: 0, y: 0, z: 0 }
        }
      })
      this.$refs.acceptanceModal.show(updatedItems, this.acceptance)
    },
    integrations (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      this.warehouseOwner = this.acceptance?._embedded?.warehouse?._embedded.owner._links.self.href.split('/').pop()
      query.filter = [
        {
          type: 'innerjoin',
          field: 'sources',
          alias: 's',
          parentAlias: 'ii'
        },
        {
          type: 'eq',
          field: 'owner',
          value: this.warehouseOwner,
          alias: 's'
        }]

      return this.$service.iIntegration.getAll(query)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
            const ifAmazon = items.filter(e => e.adapter === 'amazon')

            if (ifAmazon.length > 0) {
              this.isAmazon = true
            } else {
              this.isAmazon = false
            }
            return { items, totalPages, page, totalItems }
          })
    },
    handleOfferUpdate ({ id, update }) {
      if (!this.offerChanges[id]) {
        this.offerChanges[id] = {}
      }

      this.offerChanges[id] = {
        ...this.offerChanges[id],
        ...update
      }

      this.items = this.items.map(item => {
        if ((item._embedded.productOffer || {}).id == id) {
          return {
            ...item,
            isUpdated: true,
            _embedded: {
              ...item._embedded,
              productOffer: {
                ...item._embedded.productOffer,
                ...this.offerChanges[id]
              }
            }
          }
        }

        return item
      })

      this.$emit('items-change', this.items)
    },
    handleOfferSubmit (productOffer) {
      this.loadOfferWithDimensions(productOffer)

      this.items = this.items.map(x => {
        if (x._embedded.productOffer && `${x._embedded.productOffer.id}` === `${productOffer.id}`) {
          return {
            ...x,
            _embedded: {
              ...x._embedded,
              productOffer
            }
          }
        }

        return x
      })
    },
    handleShowInfo (item) {

      if (item._embedded.productOffer._embedded.virtualShop) {
        const shopID = item._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()

        const options = {
          warning: this.$t('All changes won\'t be affected on the already existing documents and received products!')
        }

        this.loadShop(shopID)
            .then(e => {
              const product = {
                ...item,
                ...item._embedded.productOffer,
                _embedded: {
                  shop: e
                }
              }
              this.$refs.offerModal.open(product, options)
            })
      } else {
        const shopID = item._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()

        const options = {
          warning: this.$t('All changes won\'t be affected on the already existing documents and received products!')
        }

        this.loadShop(shopID)
            .then(e => {
              const product = {
                ...item,
                ...item._embedded.productOffer,
                _embedded: {
                  shop: e
                }
              }
              this.$refs.offerModal.open(product, options)
            })
      }

    },
    handleOfferSearch (item) {
      this.openOffersSearch = true

      this.options.item = item
    },
    closeOffersSearch () {
      this.openOffersSearch = false

      this.options.item = null
    },
    setType (type) {
      const types = {
        all: 'withoutBarcode',
        withoutBarcode: 'all'
      }

      if (!types[type]) {
        return this.addErrorNotification('Type is not recognized!')
      }

      this.type = types[type]
    },
    filterProductsByType () {
      this.setType(this.type)

      if (!this.acceptance.id) {
        return
      }

      return this.onRequest({ pagination: { page: 1, per_page: 50 } })
    },
    refresh () {
      this.updatedItems = {}
      this.items = []

      if (this.acceptance && this.acceptance.id) {
        return this.onRequest({ pagination: { per_page: 50, page: 1 } })
      }

      return Promise.reject(new Error('Acceptance is not created!'))
    },
    setItems (items) {
      this.items = this.convertProductsToItems(items)
    },
    getItems () {
      return this.items
    },
    getUpdatedItems () {
      if (this.acceptance && this.acceptance.id) {
        this.updatedItems[this.pagination.page] = this.items.filter(x => x.isUpdated)
        return Object.values(this.updatedItems).reduce((acc, items) => ([...acc, ...items]), [])
      }

      return this.items
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      this.isLoading = true

      if (!query.filter) {
        query.filter = []
      }
      query['order-by'] = [
        {
          type: 'field',
          field: 'id',
          direction: 'desc'
        }
      ]

      if (this.search && this.search[this.search.length - 1] !== '*' && this.search[this.search.length - 2] !== ':' && !this.search.includes('%')) {
        query.search = this.search + (this.search[this.search.length - 1] === ':' ? '*' : ':*')
      } else if (this.search.includes('%')) {
        query.search = this.search
      }

      if (this.type === 'withoutBarcode') {
        query.filter = query.filter.filter(x => x.field !== 'sku')
        query.filter.push({ type: 'like', field: 'sku', value: 'S/I/PO%' })
      }

      query.filter.push({ field: 'document', type: 'eq', value: this.acceptance.id })

      return this.$service.acceptanceItem.getAllForAcceptance(query, this.acceptance.state)
          .then(({ items, totalPages, page, totalItems }) => {

            if (!this.updatedItems[page - 1]) {
              this.updatedItems[page - 1] = []
            }

            this.updatedItems[page - 1] = [...this.updatedItems[page - 1], ...this.items.filter(x => x.isUpdated)]

            this.pagination = {
              ...this.pagination,
              // Their is a problem with page in response. It is always 1 so we set it manually
              page: query.page || 1,
              rowsNumber: totalItems
            }

            this.items = this.updatedItems[page]

                ? items.map(x => {
                  const updatedItem = this.updatedItems[page].find(i => i.id === x.id && i.originalSku === x.originalSku)
                  return updatedItem || x
                })
                : items

            this.setAcceptanceItems(_.cloneDeep(items))

            if (items[0] && items[0]._embedded && items[0]._embedded.productOffer && items[0]._embedded.productOffer._embedded.virtualShop._links.self.href) {
              const virtualShopGetUrl = items[0]._embedded.productOffer._embedded.virtualShop._links.self.href
              this.changeToVirtualShop(virtualShopGetUrl)
            }

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    changeToVirtualShop (url) {
      this.loadVirtualShop(url).then(() => {
        this.options.defaultValues.shop = this.virtualShop
      })
    },
    handleChange () {
      this.$emit('items-change', this.items)
    },
    handleSearch (value) {
      this.search = value

      if (this.acceptance && this.acceptance.id) {
        return this.onRequest({ pagination: { per_page: 50, page: 1 } })
      }

      return Promise.resolve(this.currentAcceptanceItems)
    },
    createItem (row, acceptance) {
      let shop = row._embedded?.shop
          ? row._embedded.shop.id
          : row.shop

      if (!shop) {
        shop = row._embedded.productOffer?._embedded?.shop
        if (!shop) {
          shop = row._embedded.productOffer?._embedded?.virtualShop
        }

        if (shop) {
          if (shop.id) {
            shop = shop.id
          } else {
            shop = shop._links.self.href.split('/').pop()
          }
        }
      }

      if (row.id) {
        let item = {
          id: row.id,
          comment: row.comment
              ? row.comment
              : undefined
        }

        if (row._embedded?.productOffer?.id) {
          item.productOffer = {
            id: row._embedded.productOffer.id,
            shop: shop
          }
        }
        if ((this.isEmployee || this.isSupervisior) || (this.isClient && acceptance.state === 'new')) {
          item = {
            ...item,
            itemsStatus: row.itemsStatus
          }
        }
        if (
            ((this.isEmployee || this.isSupervisior) && acceptance.state !== 'closed') ||
            this.isClient && acceptance.state === 'new'
        ) {
          console.log(row)
          item = {
            ...item,
            price: row.price,
            purchasingPrice: row.purchasingPrice,
            quantityExpected: row.quantityExpected,
            quantityReal: row.quantityReal,
            sku: row.sku
                ? `${row.sku}`.trim()
                : undefined,
            trackingNumber: row.trackingNumber,
            batch: row.batch,
            state: row.state,
            expires: row.expires,
          }
        }

        if (row.isDeleted) {
          item.state = 'deleted'
        }

        return item
      }

      let itemsStatus = row.itemsStatus
      if (!itemsStatus) {
        itemsStatus = this.acceptance?.itemsStatus
      }

      const item = {
        document: acceptance.id,
        price: row.price,
        purchasingPrice: row.purchasingPrice,
        quantityExpected: row.quantityExpected,
        quantityReal: row.quantityReal,
        batch: row.batch
            ? row.batch
            : undefined,
        expires: row.expires
            ? row.expires
            : undefined,
        itemsStatus,
        state: row.state,
        sku: row.sku
            ? `${row.sku}`.trim()
            : undefined,
        trackingNumber: row.trackingNumber,
        comment: row.comment
            ? row.comment
            : undefined
      }

      if (shop) {
        item.shop = shop
      }

      if (row._embedded?.productOffer?.id) {
        item.productOffer = {
          id: row._embedded.productOffer.id,
          shop: shop
        }
      }

      if (acceptance.state === 'checking') {
        item.expected = 0
      }

      return item
    },
    saveOffers () {
      const offers = this.getUpdatedItems().reduce((acc, x) => {
        if (x._embedded.productOffer) {
          acc[x._embedded.productOffer.id] = {
            ...x._embedded.productOffer,
            _embedded: x._embedded
          }
        }

        return acc
      }, {})

      const queue = Object.entries(this.offerChanges).reduce((acc, [id, value]) => {
        if (offers[id]) {
          acc.push({ id, value })
        }

        return acc
      }, [])

      if (queue.length <= 0) {
        return Promise.resolve([])
      }

      const options = {
        queue,
        value: (data) => {
          return data.value
        }
      }

      const service = {
        save: (data, id) => {
          let shop = offers[id]._embedded?.productOffer?._embedded?.virtualShop
          if (!shop) {
            shop = offers[id]._embedded?.productOffer?._embedded?.shop
          }

          return this.$service.offer.save(data, id, shop ? shop._links.self.href.split('/').pop() : null)
        }
      }

      return this.$refs.tasksModal.open(service, options)
          .then(result => {
            this.$refs.tasksModal.hide()
            return result
          })
    },
    save (acceptance) {
      const items = this.getUpdatedItems().map(x => {
        return this.createItem(x, acceptance)
      })
      if (items.length <= 0) {
        return Promise.resolve({ items: [] })
      }

      console.log(items)

      return this.$service.acceptanceItem.saveAll(items, acceptance.state)
          .then(() => {
            return this.saveOffers()
          })
          .catch(err => {
            const data = {
              noCancel: true,
              okColor: 'negative',
              description: err.message
            }

            return this.$refs.confirmModal.show(data)
                .then(() => {
                  return true
                })
          })
    },
    handleNewItem () {
      let barcode = 'PO-' + (this.acceptance?.id ? this.acceptance.id + '-' : '') + new Date().getTime()

      let offer = {
        id: null,
        article: null,
        type: 'simple',
        barcodes: [barcode],
        sku: barcode,
        dimensions: {},
        weight: 0,
        volume: 0,
        price: null,
        purchasingPrice: null,
        _embedded: {
          shop: null
        },
      }

      const product = {
        document: this.acceptance.id,
        sku: barcode,
        quantityExpected: 1,
        _embedded: {
          productOffer: null,
          shop: offer._embedded?.shop
        }
      }

      this.items.unshift(product)
      this.$emit('items-change', this.items)
      this.pagination.rowsNumber = this.items.length

      if (this.acceptance?.id) {
        return this.$service.acceptanceItem.save(product, null, this.acceptance.state)
            .then(acceptanceItem => {
              console.log(acceptanceItem)
            })
      }
    },
    handleDynamicLocation () {
      this.$refs.dynamicLocationModal.show(this.acceptance._embedded?.warehouse)
    },
    handleSubmit (data) {
      this.$emit('change', {
        ...this.acceptance,
        _embedded: { ...this.acceptance._embedded, warehouse: data.warehouse }
      })

      this.items = this.convertProductsToItems(data.products)
      this.setAcceptanceItems(_.cloneDeep(data.products))
      this.$emit('items-change', this.items)
      this.pagination.rowsNumber = this.items.length

      return this.items
    },
    handleItemChange (data) {
      let i = this.items.findIndex(x => x.id === data.item)

      this.items[i] = { ...this.items[i], ...data.product }
      this.items[i].isUpdated = true

      this.$emit('items-change', this.items)
    },
    getDimensions (dimensions) {
      if (!dimensions) {
        return { x: 0, y: 0, z: 0 }
      }

      if (typeof dimensions === 'string') {
        return JSON.parse(dimensions)
      }

      return dimensions
    },
    convertProductsToItems (products) {
      let productsResult = products.map(x => {
        const item = {
          ...x,
          originalSku: x.barcode,
          sku: x.barcode,
          barcode: undefined,
          dimensions: this.getDimensions(x.dimensions),
          quantityExpected: x.count,
        }

        if (x._embedded?.productOffer) {
          item.productOffer = {
            id: x._embedded?.productOffer.id,
            shop: x._embedded?.shop?.id
          }
        }

        if (x.barcode === undefined) {
          if (x.sku) {
            item.sku = x.sku
          } else {
            item.sku = this.acceptance.type === 'products'
                ? `S/I/PO${item._embedded?.productOffer?.id}*`
                : `S/I/${item._embedded?.productOffer?.id}*`
          }
        }

        if (item._embedded?.item) {
          item.id = item._embedded?.item?.id

          delete item._embedded.item
        } else {
          item.id = undefined
        }

        const foundedItem = this.items.find(i => {
          return i.id == item.id && i.originalSku == x.originalSku && (i._embedded.productOffer || {}).id == (item._embedded.productOffer || {}).id
        })

        // if (!foundedItem && item._embedded.productOffer) {
        //   item._embedded.productOffer = x
        //   item._embedded.productOffer.dimensions = this.getDimensions(item._embedded.productOffer.dimensions)
        // }

        // Check for difference between existed item and submited one
        // Clone founded item to convert it from proxy to object
        item.isUpdated = !foundedItem || !!foundedItem.isUpdated || JSON.stringify(item) !== JSON.stringify({ ...foundedItem })
        return item
      })

      return productsResult
    }
  }
}
</script>
