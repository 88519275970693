<template>
  <div :key="componentKey">
    <action-header
        :is-loading="!acceptance"
        :actions-model="headerModel"
        :page="page"
    />
    <div
        v-if="acceptance?.errors"
        class="q-pa-md text-center rounded"
        :class="{ 'bg-amber-6': acceptance.state !== 'error', 'bg-red-5': acceptance.state === 'error'}"
    >
      <strong>{{ acceptance.errors }}</strong>
    </div>

    <div
        v-if="hasData"
        class="q-pa-md text-center rounded bg-amber-6"
    >
      <strong> {{ 'Restore Content' }} </strong>
      <br>

      <q-btn
          outline
          color="dark"
          label="Restore"
          @click="handleRestore"
      />

    </div>

    <div class="q-pa-md">
      <q-legend
          class="q-mb-sm"
          :label="$t('Information')"
      />

      <form-builder :schema="schema"/>

      <q-separator
          v-if="!isDisabledAdditionalProperties"
          class="q-my-md"
      />

      <acceptance-additional-properties
          v-if="!isDisabledAdditionalProperties"
          :disabled="isDisabled"
      />

      <q-separator class="q-my-md"/>

      <acceptance-delivery-request
          v-if="!isDisabledDeliveryRequest"
          :disabled="isDisabled"
          :required="isDisabledDeliveryRequestFields"
          :sender-disabled="deliveryRequestSenderDisable"
          @delivery-request-change="handleItemsChange"
          @has-tracking-number-value="handleHasTrackingNumberValue"
          @has-sender-name-value="handleHasSenderNameValue"
      />

      <q-separator class="q-my-md" v-if="!isDisabledDeliveryRequest"/>

      <acceptance-keywords
          v-if="!isDisabledDeliveryRequest"
          @change="handleAcceptanceChange"
      />

      <q-separator class="q-my-md" v-if="!isDisabledDeliveryRequest"/>

      <acceptance-items-bulk
          v-if="acceptance"
          ref="acceptanceItems"
          :acceptance="acceptance"
          :disabled="acceptance.state === 'confirmed' || acceptance.state === 'closed'"
          @items-change="handleItemsChange"
          @change="handleAcceptanceChange"
          @save="save"
      />

      <div
          v-if="isEnabledSaveMessage"
          class="bg-warning text-center q-pa-md q-ma-sm full-width rounded text-h6"
      >
        {{ $t('Click "Save" to complete') }}
      </div>
    </div>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="hasChange"
        @back="handleDiscard"
        @save="save"
    />

    <q-dialog v-model="areYouSure">
      <q-card style="max-width: 30vw">
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
            {{ $t('Are You Sure?') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>
        <q-card-section class="row items-center q-pb-none">
          <div>
            {{ $t('If you close this document now, all products that are not placed will be removed') }}
          </div>
        </q-card-section>
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <q-card-section class="text-center">

            <q-btn
                textarea="center"
                color="light-blue-9"
                class="q-mr-sm"
                text-color="white"
                :label="$t('Close purchase order')"
                @click="closePurchaseOrder"
            />

            <q-btn
                textarea="center"
                :label="$t('Discard')"
                @click="closeConfirmation"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="areYouSureChecking">
      <q-card style="max-width: 30vw">
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
            {{ $t('Are You Sure?') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>
        <q-card-section class="row items-center q-pb-none">
          <div>
            {{ $t('Check and update the quantity of the items in the field “Received“?') }}
          </div>
        </q-card-section>
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <q-card-section class="text-center">

            <q-btn
                textarea="center"
                color="light-blue-9"
                class="q-mr-sm"
                text-color="white"
                :label="$t('Yes')"
                @click="checkingPurchaseOrder"
            />

            <q-btn
                textarea="center"
                :label="$t('Discard')"
                @click="closeChecking"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>

    <order-acceptance-modal ref="orderModal" :warehouse="this.acceptance?._embedded?.warehouse"/>

    <acceptance-services-modal ref="acceptanceServices"/>

    <place-items-modal ref="placeItemsModal" @submit="handleAllocate"/>

    <chat-modal ref="chatModal"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader'
import AcceptanceAdditionalProperties
  from '../../components/acceptance-additional-properties/AcceptanceAdditionalProperties'
import AcceptanceDeliveryRequest from '../../components/acceptance-delivery-request/AcceptanceDeliveryRequest'
import ChatModal from '../../components/chat/ChatModal'
import PlaceItemsModal from '../../components/modals/PlaceItemsModal.vue'
import AcceptanceItemsBulk from '../../components/products/AcceptanceItemsBulk.vue'
import OrderAcceptanceModal from '../../components/modals/OrderAcceptanceModal.vue'
import AcceptanceKeywords from '@/apps/app/components/acceptance-keywords-doc-extended/AcceptanceKeywords'

// Helpers
import { getRouteOptions } from '../../helpers/helpers'
import { transliterate as tr } from 'transliteration'
import AcceptanceServicesModal from '@/apps/app/components/modals/AcceptanceServicesModal.vue'
import _ from 'lodash'

export default {
  name: 'Acceptance',
  components: {
    AcceptanceServicesModal,
    ActionHeader,
    AcceptanceAdditionalProperties,
    AcceptanceDeliveryRequest,
    AcceptanceKeywords,
    ChatModal,
    PlaceItemsModal,
    AcceptanceItemsBulk,
    OrderAcceptanceModal
  },
  data () {
    return {
      isSaveLoading: false,
      componentKey: 0,
      isEnabledSaveMessage: false,
      warehouseDisable: false,
      deliveryRequestSenderDisable: false,
      isDisabledConfirmed: false,
      isDisabledChecking: false,
      hasSenderNameValue: false,
      hasTrackingNumberValue: false,
      areYouSure: false,
      areYouSureChecking: false,
      types: [
        { id: 'products', name: this.$t('Products') },
        { id: 'items', name: this.$t('Items') },
        { id: 'palletized', name: this.$t('Palletized') },
        { id: 'return', name: this.$t('Returns') }
      ],
      states: [
        { id: 'new', name: this.$t('New') },
        { id: 'draft', name: this.$t('Draft') },
        { id: 'checking', name: this.$t('Checking') },
        { id: 'error', name: this.$t('Error') },
        { id: 'confirmed', name: this.$t('Confirmed') },
        { id: 'closed', name: this.$t('Closed') }
      ],
      statuses: [
        { id: 'normal', name: this.$t('Normal') },
        { id: 'blocked', name: this.$t('Blocked') }
      ],
      hasChange: false,
      hasType: false,
      items: [],
      updatedItems: {},
    }
  },
  watch: {
    $route () {
      // Increment the component key value when the route changes
      this.newMounted()
      this.componentKey++
    },
  },
  computed: {
    ...mapGetters([
      'acceptance',
      'acceptanceItems',
      'appOptions',
      'isClient',
      'isAdministrator',
      'isSupervisior',
      'isEmployee'
    ]),
    hasData () {
      // Check if there is data in local storage
      if (!this.acceptance && localStorage.getItem('acceptance') !== null) {
        return true
      }
      if (localStorage.getItem('acceptanceItems') !== null && (!this.acceptanceItems || this.acceptanceItems.length === 0)) {
        return true
      }
      return false
    },
    page () {
      return {
        id: this.acceptance && this.acceptance.id,
        name: this.acceptance && this.acceptance.id
            ? this.$t('Purchase order')
            : this.$t('New purchase order')
      }
    },
    isWarehouseDisabled () {
      return this.warehouseDisable || this.isDisabled || (this.acceptanceItems.length > 0 && this.acceptance?._embedded?.warehouse)
    },
    headerModel () {
      if (!this.acceptance) {
        return []
      }

      const newState = { id: 'new', title: this.$t('New'), transitions: ['draft', 'cancel'], color: 'info' }
      const draft = { id: 'draft', title: this.$t('Draft'), transitions: ['new'], color: 'info' }
      const checking = { id: 'checking', title: this.$t('Checking'), transitions: [], color: 'warning' }
      const confirmed = { id: 'confirmed', title: this.$t('Confirmed'), transitions: [], color: 'yellow' }
      const error = { id: 'error', title: this.$t('Error'), transitions: [], color: 'yellow' }
      const closed = { id: 'closed', title: this.$t('Closed'), transitions: [], color: 'dark' }
      const complete = { id: 'complete', title: this.$t('Complete'), transitions: [], color: 'success' }
      const deleted = { id: 'deleted', title: this.$t('Deleted'), transitions: [], color: 'danger' }
      const preset = { id: 'preset', title: this.$t('Preset'), transitions: [], color: 'warning' }
      const archived = { id: 'archived', title: this.$t('Archived'), transitions: [], color: 'dark' }
      const cancel = { id: 'cancel', title: this.$t('Cancel'), transitions: [], color: 'danger' }

      // if (this.acceptance && this.acceptance.id) {
      //   if (this.isSupervisior || this.isAdministrator) {
      //     if (this.acceptanceItems.find(item => item._embedded && !item._embedded.place)) {
      //       confirmed.transitions = ['closed']
      //     }
      //   }
      //
      //   if (!this.isClient || this.isEmployee) {
      //     draft.transitions = ['new']
      //     newState.transitions = ['checking', 'confirmed']
      //     error.transitions = ['confirmed']
      //     checking.transitions = ['confirmed']
      //   }
      //
      //   if (this.isClient) {
      //     draft.transitions = ['new']
      //     newState.transitions = ['draft']
      //   }
      // }

      const states = [
        { buttons: [newState, draft, checking, confirmed, closed, complete, error, deleted, preset, archived, cancel] }
      ]

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: states[0].buttons[0].color,
        editableFields: false,
        onClick: (state) => {
          if (state === 'closed') {
            this.areYouSure = true
          } else {
            return this.$service.acceptance.save({ state }, this.acceptance.id)
                .then(acceptance => {
                  this.setAcceptance(acceptance)
                  this.upsertAcceptance(acceptance)
                  this.$refs.acceptanceItems.refresh()
                })
          }
        },
        value: states[0].buttons[0],
        options: states
      }

      const dropdown = {
        section: 'Dropdown',
        className: 'text-center q-pb-sm fit--mobile',
        options: [
          option
        ]
      }

      if (this.acceptance && this.acceptance.state !== option.value.id) {
        states.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === this.acceptance.state
          })

          if (status) {
            option.color = group.color || status.color
            option.value = status
          }

          return status
        })
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-6 col-sm-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              additionalValueClassName: 'col-6 col-sm-12 q-px-xs',
              type: 'text',
              value: this.acceptance.id
                  ? this.$t('Purchase Orders ID: ') + this.acceptance.id
                  : this.$t('New purchase order'),
              additionalValue: this.acceptance && this.acceptance.type ? `${this.$t('Type')}: ${this.$t(this.acceptance.type)}` : ''
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-12 col-sm text-center row items-center justify-center q-gutter-y-xs q-py-xs',
          options: [
            {
              id: 'checkingMode',
              wrapperClassName: 'q-pa-xs',
              type: 'button',
              variant: 'light',
              label: this.$t('Checking mode'),
              disabled: this.handleIfCheckingMode,
              onClick: () => {
                this.areYouSureChecking = true
              }
            },
            {
              id: 'print',
              wrapperClassName: 'q-pa-xs',
              type: 'button',
              icon: 'print',
              hasIcon: true,
              variant: 'light',
              disabled: !this.acceptance || !this.acceptance.id,
              onClick: this.handlePrint
            },
            {
              id: 'chat',
              wrapperClassName: 'q-pa-xs',
              type: 'button',
              icon: 'comments',
              hasIcon: true,
              variant: 'light',
              disabled: !this.acceptance || !this.acceptance.id,
              onClick: () => {
                const entities = [
                  { entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance', entity: this.acceptance.id }
                ]

                this.$refs.chatModal.open(entities)
              }
            },
            {
              if: !this.isClient,
              id: 'place',
              wrapperClassName: 'q-pa-xs',
              type: 'button',
              icon: 'place',
              hasIcon: true,
              variant: 'light',
              disabled: !this.acceptance || !(this.acceptance.state === 'confirmed' && this.acceptanceItems.find(item => item.quantityPlace < this.quantityConfirmed(item))) || this.isDisabledChecking,
              onClick: () => {
                const items = this.acceptanceItems.filter(item => item.quantityPlace < this.quantityConfirmed(item))
                this.$refs.placeItemsModal.show(this.acceptance, items, this.acceptance._embedded.warehouse, this.acceptance.type === 'palletized' ? 'pallet' : 'static')
              }
            },
            {
              id: 'services',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              variant: 'light',
              disabled: !this.acceptance || !this.acceptance.id,
              label: this.$t('Services'),
              onClick: () => {
                const defaultData = {
                  state: 'active',
                  document: this.acceptance.id,
                  warehouse: this.acceptance._embedded.warehouse,
                }

                this.$refs.acceptanceServices.open(this.acceptance.id, defaultData)
              }
            },
            {
              id: 'addOrder',
              wrapperClassName: 'q-pa-xs',
              type: 'button',
              variant: 'light',
              label: this.$t('Close the PO'),
              disabled: this.handleIfClose,
              onClick: () => {

                this.areYouSure = true

              }
            }
          ]
        },
        dropdown
      ]
    },
    handleIfClose () {
      if (this.isSupervisior || this.isAdministrator) {

        if (this.acceptanceItems.find(item => item._embedded && !item._embedded.place) && this.acceptance.state === 'confirmed') {
          return false
        }
      }
      return true
    },
    handleIfCheckingMode () {
      if (this.acceptance && ((!this.isClient || this.isEmployee) && this.acceptance.state === 'new')) {
        return false
      }
      return true
    },
    isNewAcceptance () {
      return this.acceptance?.state === 'new'
    },
    isDisabledStatus () {
      if (this.acceptance?.itemsStatus === 'normal' && this.acceptance.state !== 'new') {
        return true
      }
      return false
    },
    isDisabledType () {
      if (!this.acceptance) {
        return false
      }

      if (!this.acceptance.id) {
        return false
      }

      return this.acceptance.state !== 'new' || this.acceptanceItems.length > 0
    },
    isDisabled () {
      if (!this.acceptance || !this.acceptance.id) {
        return false
      }
      return this.acceptance?.state !== 'new' && this.acceptanceItems.length > 0
    },
    schema () {
      return {
        isLoading: !this.acceptance,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('External id'),
                field: 'extId',
                value: this.acceptance && this.acceptance.extId,
                wrapperStyleClasses: 'col-12 col-md-4 col-sm-6 q-pa-xs',
                disabled: this.isDisabled,
                onChange: extId => {
                  this.hasChange = true
                  this.updateAcceptance({ extId })
                  localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))
                }
              },
              {
                type: 'date',
                label: this.$t('External date'),
                field: 'extDate',
                value: this.acceptance && this.acceptance.extDate,
                wrapperStyleClasses: 'col-12 col-md-2 col-sm-6 q-pa-xs',
                disabled: this.isDisabled,
                get (extDate) {
                  return extDate && typeof extDate === 'object'
                      ? extDate.date
                      : extDate
                },
                onChange: (extDate) => {
                  this.hasChange = true
                  this.updateAcceptance({ extDate })
                  localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                value: this.acceptance?._embedded?.warehouse,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                disabled: this.isWarehouseDisabled,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' },
                      { type: 'in', field: 'type', values: ['client', 'virtual'] }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: (warehouse) => {
                  this.hasChange = true
                  this.updateAcceptanceEmbedded({ warehouse })
                  localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))
                  this.loadSenders()
                }
              },
              {
                type: 'select',
                label: this.$t('Status'),
                field: 'itemsStatus',
                value: this.acceptance && this.acceptance.itemsStatus,
                wrapperStyleClasses: 'col-12 col-md-4 col-sm-6 q-pa-xs',
                options: this.statuses,
                disabled: this.isDisabledStatus,
                customLabel (row) {
                  return row && typeof row === 'object'
                      ? row.name
                      : row
                },
                onChange: (itemsStatus) => {
                  this.hasChange = true
                  this.updateAcceptance({ itemsStatus: itemsStatus.id })
                  localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                field: 'type',
                disabledClean: true,
                value: this.acceptance && this.acceptance.type,
                wrapperStyleClasses: 'col-12 col-md-2 col-sm-6 q-pa-xs',
                options: this.types,
                disabled: this.isDisabledType,
                customLabel (row) {
                  return row && typeof row === 'object'
                      ? row.name
                      : row
                },
                onChange: (type) => {
                  this.hasChange = true
                  this.updateAcceptance({ type: type.id })
                  localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Comment'),
                field: 'comment',
                value: this.acceptance && this.acceptance.comment,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                hint: this.$t('Max 255 characters.'),
                max: 255,
                disabled: !this.acceptance || this.acceptance.state === 'confirmed',
                onInput: comment => {
                  this.hasChange = true
                  this.updateAcceptance({ comment })
                  localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))
                }
              }
            ]
          }
        ]
      }
    },
    isDisabledAdditionalProperties () {
      const options = getRouteOptions('acceptance')

      if (options.additionalProperties && options.additionalProperties.disabledFor) {
        const disabledFor = options.additionalProperties.disabledFor

        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
          return true
        }

        if (disabledFor.roles) {
          const roles = (JSON.parse(localStorage.getItem('userData')) || {}).roles || []
          return roles.find(({ id }) => disabledFor.roles.includes(id))
        }
      }

      return false
    },
    isDisabledDeliveryRequest () {
      const options = getRouteOptions('acceptance')

      if (options.deliveryRequest?.disabledFor) {
        const disabledFor = options.deliveryRequest.disabledFor

        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
          return true
        }

        if (disabledFor.roles) {
          const roles = (JSON.parse(localStorage.getItem('userData')) || {}).roles || []
          return roles.find(({ id }) => disabledFor.roles.includes(id))
        }
      }

      return false
    },
    isDisabledDeliveryRequestFields () {
      const options = getRouteOptions('acceptance')

      if (options.deliveryRequestFields?.disabledFor) {
        const disabledFor = options.deliveryRequestFields.disabledFor

        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
          return true
        }

        if (disabledFor.roles) {
          const roles = (JSON.parse(localStorage.getItem('userData')) || {}).roles || []
          return roles.find(({ id }) => disabledFor.roles.includes(id))
        }
      }

      return false
    }
  },
  mounted () {
    if (!this.$route.params.id) {
      if (this.acceptance && this.acceptance._embedded && !this.acceptance._embedded.warehouse) {
        this.setNewAcceptanceData()
      }

      const options = getRouteOptions('acceptance')

      if (options.statusFor && options.statusFor.disabledFor) {
        const disabledFor = options.statusFor.disabledFor
        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.domain)) {
          this.updateAcceptance({ itemsStatus: 'blocked' })
        } else {
          this.updateAcceptance({ itemsStatus: 'normal' })
        }
      }

      if (this.$route.params.type && this.types.find(x => x.id === this.$route.params.type)) {
        this.updateAcceptance({ type: this.$route.params.type })
        this.hasType = true
      }

      this.$nextTick(() => {
        if (!this.$route.query.hasItems) {
          this.$refs.acceptanceItems.setItems([])
        } else {
          this.$refs.acceptanceItems.setItems([...this.acceptanceItems])
          this.hasChange = true
        }
      })
    } else {
      // Block confirmed and checking for client users
      this.isDisabledConfirmed = this.getIsDisabledConfirmed()
      this.isDisabledChecking = this.getIsDisabledConfirmed()

      if (!this.acceptance) {
        this.loadAcceptance(this.$route.params.id).then(acceptance => {
          this.loadSenderData()
        })
      } else {
        this.loadSenderData()
      }
    }

    if (this.$route.query.hasItems && (!this.acceptance || !this.acceptance._embedded || !this.acceptance._embedded.warehouse)) {
      if (this.acceptanceItems && this.acceptanceItems.length > 0 && this.acceptanceItems[0]?._embedded?.productOffer) {
        let testItem = this.acceptanceItems[0]
        let shop

        if (testItem?._embedded?.productOffer?._embedded?.virtualShop) {
          shop = testItem?._embedded?.productOffer?._embedded?.virtualShop.id
        } else if (testItem?._embedded?.productOffer?._embedded?.shop) {
          shop = testItem?._embedded?.productOffer?._embedded?.shop.id
        } else {
          shop = testItem?._embedded?.shop.id
        }

        this.$service.shop.get(shop)
            .then(shop => {
              const query = {
                per_page: 250,
                page: 1,
                filter: [
                  { type: 'eq', field: 'state', value: 'active' },
                  { type: 'in', field: 'type', values: ['client', 'virtual'] }
                ]
              }

              if (shop && shop._embedded.owner) {
                query.filter.push({ type: 'eq', field: 'owner', value: shop._embedded.owner.id })
              }

              return this.$service.warehouse.getAll(query)
                  .then(({ items, totalItems }) => {
                    let warehouse = items[0]

                    if (totalItems === 1) {
                      this.updateAcceptanceEmbedded({ warehouse })
                      this.loadSenders()
                    }

                    return warehouse
                  })
            })
        return
      }
    }

    const query = {
      per_page: 25,
      page: 1,
      filter: [
        { type: 'eq', field: 'state', value: 'active' },
        { type: 'in', field: 'type', values: ['client', 'virtual'] }
      ]
    }

    this.$service.warehouse.getAll(query).then((warehouses) => {
      if (warehouses.totalItems === 1) {
        let warehouse = warehouses.items[0]
        this.updateAcceptanceEmbedded({ warehouse })
        this.loadSenders()
      }
    })
  },
  unmounted () {
    this.setAcceptance(null)
    this.setAcceptanceItems([])
    this.setCleanAcceptanceItems([])
    localStorage.removeItem('acceptance')
    localStorage.removeItem('acceptanceItem')
  },
  methods: {
    ...mapMutations([
      'updateAcceptance',
      'setNewAcceptance',
      'setAcceptance',
      'setAcceptances',
      'setAcceptanceItems',
      'setCleanAcceptanceItems',
      'addWarningNotification',
      'upsertAcceptance',
      'updateAcceptanceEmbedded',
      'addErrorNotification'
    ]),
    ...mapActions([
      'saveAcceptance',
      'loadAcceptance',
      'loadVirtualShop'
    ]),
    quantityConfirmed (item) {
      if (this.acceptance.state === 'new') {
        return item.quantityExpected
      } else if (this.acceptance.state === 'confirmed' || this.acceptance.state === 'checking') {
        return item.quantityReal
      } else {
        return item.quantityPlace
      }
    },
    handlePrint () {
      const warehouse = tr(this.acceptance?._embedded?.warehouse.name)
      this.$service.printer.generateBarcode({
        barcode: `P/O/${this.acceptance.id}*`,
        label: `${warehouse}`,
        size: '88x44',
        fontSize: '13'
      })
    },
    handleAcceptanceChange (acceptance) {
      this.updateAcceptance(acceptance)
      localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))
      this.hasChange = true
    },
    newMounted () {
      if (!this.$route.params.id) {
        // this.setNewAcceptanceData()

        const options = getRouteOptions('acceptance')

        if (options.statusFor && options.statusFor.disabledFor) {
          const disabledFor = options.statusFor.disabledFor
          if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.domain)) {
            this.updateAcceptance({ itemsStatus: 'blocked' })
          } else {
            this.updateAcceptance({ itemsStatus: 'normal' })
          }
        }

        if (this.$route.params.type && this.types.find(x => x.id === this.$route.params.type)) {
          this.updateAcceptance({ type: this.$route.params.type })
          this.hasType = true
        }

        this.$nextTick(() => {
          if (this.$refs.acceptanceItems) {
            if (!this.$route.query.hasItems) {
              this.$refs.acceptanceItems.setItems([])
            } else {
              this.$refs.acceptanceItems.setItems([...this.acceptanceItems])
              this.hasChange = true
            }
          }
        })

      } else {
        this.$router.go()
        // // Block confirmed and checking for client users
        // this.isDisabledConfirmed = this.getIsDisabledConfirmed()
        // this.isDisabledChecking = this.getIsDisabledConfirmed()
        //
        // if (this.acceptance) {
        //   this.loadAcceptance(this.$route.params.id).then(acceptance => {
        //     this.loadSenderData()
        //   })
        // } else {
        //   this.loadSenderData()
        // }
      }

    },
    handleItemsChange (items) {
      localStorage.setItem('acceptanceItems', JSON.stringify(_.cloneDeep(items)))
      this.hasChange = true
      this.isEnabledSaveMessage = true
    },
    handleDeliveryRequestChange () {
      this.hasChange = true
      this.isEnabledSaveMessage = true
    },
    handleAllocate () {
      this.$refs.acceptanceItems.refresh()
    },
    getIsDisabledConfirmed () {
      const blockedRoles = [8]

      return JSON.parse(localStorage.getItem('userData')).roles.find(role => {
        return blockedRoles.includes(role.id)
      })
    },
    setNewAcceptanceData () {
      this.setNewAcceptance()

      const query = {
        per_page: 25,
        page: 1
      }

      return this.$service.warehouse.getAll(query)
          .then(({ items }) => {
            // Set warehouse if it is only one
            if (items.length === 1) {
              this.updateAcceptance({
                _embedded: {
                  ...this.acceptance._embedded,
                  warehouse: items[0]
                }
              })

              this.loadSenders()

              this.warehouseDisable = true
            }
          })
    },
    save () {
      if ((this.acceptance.comment || '').length > 255) {
        this.addErrorNotification('Max comment length is 255 characters!')
        return
      }

      if (this.acceptance._embedded.deliveryRequest && !this.isDisabledDeliveryRequest) {
        if (!this.acceptance._embedded.deliveryRequest.senderName && !this.hasSenderNameValue && !this.acceptance.state) {
          this.addErrorNotification('The sender name is empty')
          return
        }
        if (this.isDisabledDeliveryRequestFields) {
          if (!this.acceptance._embedded.deliveryRequest.trackingNumber && !this.hasTrackingNumberValue && !this.acceptance.state) {
            this.addErrorNotification('The tracking number is empty')
            return
          }

          if (!this.acceptance._embedded.deliveryRequest._embedded.deliveryService) {
            this.addErrorNotification('The carrier is empty')
            return
          }
        }

      }

      this.isSaveLoading = true
      this.saveAcceptance()
          .then(item => {
            this.upsertAcceptance(item)
            this.setAcceptance(item)
            localStorage.removeItem('acceptance')
            localStorage.removeItem('acceptanceItems')
            return this.$refs.acceptanceItems.save(item)
          })
          .then((errorOrItems) => {
            this.hasChange = false
            this.isEnabledSaveMessage = false
            if (!this.$route.params.id && typeof errorOrItems !== 'boolean') {
              this.handleBack()
            } else {
              this.$refs.acceptanceItems.refresh()
            }
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    refresh () {
      this.loadAcceptance(this.$route.params.id)
      this.$refs.acceptanceItems.refresh()
      this.hasChange = false
    },
    loadSenderData () {
      if (this.acceptance?._embedded?.deliveryRequest) {
        if (this.acceptance._embedded.deliveryRequest?._embedded?.sender) {
          if (!this.acceptance._embedded.deliveryRequest._embedded.sender.name && this.acceptance._embedded.deliveryRequest._embedded.sender._links) {
            this.$service.httpClient.getHref(this.acceptance._embedded.deliveryRequest._embedded.sender._links.self.href).then(sender => {
              this.acceptance._embedded.deliveryRequest._embedded.sender = sender
            })
          }
        }
        if (this.acceptance._embedded.deliveryRequest?._embedded?.deliveryService) {
          if (!this.acceptance._embedded.deliveryRequest._embedded.deliveryService.name && this.acceptance._embedded.deliveryRequest._embedded.deliveryService._links) {
            this.$service.httpClient.getHref(this.acceptance._embedded.deliveryRequest._embedded.deliveryService._links.self.href).then(deliveryService => {
              this.acceptance._embedded.deliveryRequest._embedded.deliveryService = deliveryService
            })
          }
        }
      }
    },
    handleHasTrackingNumberValue (item) {
      this.hasTrackingNumberValue = item
    },
    handleHasSenderNameValue (item) {
      this.hasSenderNameValue = item
    },
    checkingPurchaseOrder () {
      return this.$service.acceptance.save({ state: 'checking' }, this.acceptance.id)
          .then(acceptance => {
            this.setAcceptance(acceptance)
            this.upsertAcceptance(acceptance)
            this.areYouSureChecking = false
            this.$refs.acceptanceItems.refresh()
          })
    },
    closePurchaseOrder () {
      return this.$service.acceptance.save({ state: 'closed' }, this.acceptance.id)
          .then(acceptance => {
            this.setAcceptance(acceptance)
            this.upsertAcceptance(acceptance)
            this.areYouSure = false
            this.$refs.acceptanceItems.refresh()
          })
    },
    closeChecking () {
      this.areYouSureChecking = false
    },
    closeConfirmation () {
      this.areYouSure = false
    },
    handleRestore () {
      this.setAcceptance(JSON.parse(localStorage.getItem('acceptance')))

      const storedData = JSON.parse(localStorage.getItem('acceptanceItems'))
      if (!storedData) {
        this.$refs.acceptanceItems.setItems([])
      } else {
        this.$refs.acceptanceItems.setItems(storedData)
        this.hasChange = true
      }
    },
    loadSenders () {
      const query = {
        per_page: 25,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      if (this.acceptance?._embedded?.warehouse?._embedded?.owner) {
        query.filter.push({
          type: 'eq', field: 'owner', value: this.acceptance?._embedded?.warehouse?._embedded?.owner?.id
        })
      }

      return this.$service.sender.getAll(query).then(result => {
        if (result.totalItems === 1) {
          this.updateAcceptanceEmbedded({ deliveryRequest: { _embedded: { sender: result.items[0] } } })
          localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))

          if (!this.acceptance?._embedded?.deliveryRequest?.senderName) {
            this.updateAcceptanceEmbedded({ deliveryRequest: { senderName: result.items[0].name } })
            localStorage.setItem('acceptance', JSON.stringify(_.cloneDeep(this.acceptance)))
          }

          this.deliveryRequestSenderDisable = true
        }
      })
    }
  }
}
</script>
